import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const CreditCardDebtPage = () => {
    const article = {
        id: 'bb22cd86-ff9f-5c8e-835d-edf7aff4ed9d',
        title: 'How to Deal With Credit Card Debt',
        slug: '/credit-card-debt/',
        date: '2018-01-24T15:57:48.000Z',
        modified: '2021-11-04T20:03:25.000Z',
        excerpt: 'The average American balance-carrying household has over $15,000 in credit card debt. Learn more about how that debt can affect you, as well as how to manage and work your way out of it.',
        featured_image: {
            source_url: '/media/credit-card-debt.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 12,
        content: (
            <>
                <p>
                    What&rsquo;s your monthly
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=credit-card-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    payment?
                </p>
                <p>
                    <a target="_blank" href="http://fortune.com/2017/04/10/credit-card-debt-trillion-dollars/" rel="noreferrer">According to recent statistics</a>
                    , Americans have more than $1 trillion in credit card debt.
                </p>
                <p>The higher your credit card balance, the higher your minimum payment—and having more than one card further complicates things. </p>
                <p>
                    Many people who are trying to get out of credit card debt don&rsquo;t know where to begin – especially if they owe money on multiple
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=credit-card-debt&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    .
                </p>
                <p>Getting rid of your credit card debt matters for more than just your personal sanity.</p>
                <p>High credit utilization affects your ability to qualify for mortgages, be approved for auto loans, and numerous other major life purchases.</p>
                <p>Thankfully, there are ways to tackle your debt and options if you find you can&rsquo;t pay it all at once. </p>
                <p>We&rsquo;ve put together a guide on how to tackle your credit card debt, mistakes to avoid, and what to do if your credit has been damaged. </p>
                <h2 id="paying-off-your-credit-cards">Paying off your credit cards</h2>
                <p>The first step to recovering from massive credit card debt is to reduce your total balance. </p>
                <p><LazyLoadImage src="/media/estimated-total-outstanding-credit-card-debt-1.png" alt="Estimated Total Outstanding Credit Card Debt" /></p>
                <p>Here&rsquo;s how.</p>
                <h3>Pay off the highest interest credit cards first</h3>
                <p>
                    Take a look at all of your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=credit-card-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    APRs.
                </p>
                <p>Chances are, some of your credit cards have higher interest rates than others. </p>
                <p>The higher interest cards are costing you the most money, so it usually makes sense to pay them off first.&nbsp;</p>
                <p>For example, if you have two credit cards, one with a $2,000 balance at 19% APR and a second card with a $3,000 balance at 12% APR, you should make minimum payments on the $3,000 balance while paying extra money toward the 19% APR card.&nbsp;</p>
                <p>
                    If you have multiple credit cards and you want to decide which credit card to pay off first, tools like the
                    {' '}
                    <a target="_blank" href="https://www.macu.com/financial-calculators/credit-card-optimizer" rel="noreferrer">Credit Card Optimizer</a>
                    {' '}
                    can help you discover the best distribution of your credit card debt.
                </p>
                <p>
                    This free tool lets you compare multiple credit card balances and decide how to pay off your debt as fast as possible while
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=credit-card-debt&amp;sub2=saving" rel="noopener noreferrer" target="_blank">saving</a>
                    {' '}
                    as much money as possible.&nbsp;
                </p>
                <p>
                    <strong>Advantages:</strong>
                    {' '}
                    Paying off the highest interest cards first can save you the most money on credit card interest.&nbsp;
                </p>
                <p>
                    <strong>Disadvantages:</strong>
                    {' '}
                    Some people might feel discouraged if their total amount of debt doesn&rsquo;t seem to be decreasing fast enough – but in the long run, this is still the way to save the most money while paying off credit card debt.
                </p>
                <h3>Do a balance transfer</h3>
                <p>If you don&rsquo;t have enough cash to pay off your credit card debt, you can save some money on interest by doing a balance transfer. </p>
                <p>With a balance transfer, you transfer your credit card debt to a different credit card with a lower interest rate. You can either transfer debt to a credit card that you already hold, or you can apply for a new credit card account. </p>
                <p>
                    Some credit cards offer special introductory 0% interest rate on balance transfers, as long as you promise to pay off the full amount within a certain amount of time (often 6-18 months, according to
                    {' '}
                    <a target="_blank" href="https://www.discover.com/credit-cards/resources/balance-transfer.shtml" rel="noreferrer">this article from Discover Card</a>
                    ).
                </p>
                <p>
                    <strong>Advantages:</strong>
                    {' '}
                    Balance transfers can help you save money on credit card interest payments.
                </p>
                <p>
                    <strong>Disadvantages:</strong>
                    {' '}
                    Balance transfers don&rsquo;t get you out of debt, they just move the debt around.
                </p>
                <p>You still need to practice good budgeting and good financial habits to actually make changes in your spending and saving patterns. </p>
                <p>If you apply for too many credit card balance transfers, your credit score could take a hit. </p>
                <p>Balance transfers can also hurt your credit score if you transfer so much money onto one credit card that it puts your credit utilization ratio above a certain level. </p>
                <p>
                    Another potential drawback of balance transfers is balance transfer fees – depending on the credit card, balance transfer fees can cost
                    {' '}
                    <a target="_blank" href="https://www.discover.com/credit-cards/resources/balance-transfer.shtml" rel="noreferrer">3-5% of the total balance transferred</a>
                    .

                </p>
                <p>So if you transfer $5,000 of credit card debt and the fee is 4%, that would be an extra $200 added to the credit card balance.</p>
                <h3>Consolidate debts into one payment</h3>
                <p>Instead of chipping away at multiple credit cards, another solution is debt consolidation. </p>
                <p>
                    With debt consolidation, you take out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=credit-card-debt&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    to pay off your credit card debts, and then you make one monthly payment on your personal loan – often at a lower interest rate than you were paying on your credit cards.
                </p>
                <p><LazyLoadImage src="/media/u-s-personal-loans-2.png" alt="U.S. Personal Loans" /></p>
                <p>
                    For example,
                    {' '}
                    <a target="_blank" href="https://www.wellsfargo.com/personal_credit/debt_consolidation/consolidate/details" rel="noreferrer">Wells Fargo offers debt consolidation</a>
                    {' '}
                    with fixed-rate unsecured
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=credit-card-debt&amp;sub2=personal-loans" rel="noopener noreferrer" target="_blank">personal loans</a>
                    {' '}
                    for people with good credit who have high interest debt totaling $3,000 – $100,000. &nbsp;&nbsp;
                </p>
                <p>
                    <strong>Advantages:</strong>
                    {' '}
                    With debt consolidation, you&rsquo;ll often be able to get a lower monthly payment and will have more financial flexibility to save or spend each month.
                </p>
                <p>Or you can choose a debt consolidation plan that will get you out of debt faster, in exchange for a higher monthly payment. &nbsp;&nbsp;</p>
                <p>
                    <strong>Disadvantages:</strong>
                    {' '}
                    Debt consolidation does not get you &quot;out of debt,&quot; but it hopefully puts you in better position to pay off your debt while saving money on interest.
                </p>
                <p>
                    If you
                    {' '}
                    <a href="/loans/">choose a debt consolidation loan with a lower monthly payment</a>
                    , it might take you longer to get out of debt than if you had just kept paying off credit cards, but it&rsquo;s up to you – you have the option to pay extra money toward your credit card debt each month, as long as there are no prepayment penalties.&nbsp;
                </p>
                <p>
                    <a target="_blank" href="https://www.wellsfargo.com/personal_credit/debt_consolidation/consolidate/details" rel="noreferrer">Wells Fargo does not charge prepayment penalties on personal loans</a>
                    , but check the fine print before signing on any loan from any creditor, to make sure you can avoid prepayment penalties.
                </p>
                <h3>Pay off the lowest-balance card first</h3>
                <p>Most financial planners recommend paying off the highest interest credit cards first, but some people need extra momentum to feel like they are making progress on getting out of credit card debt. </p>
                <p>Another approach is to pay off the lowest balances first – this plan is recommended by personal finance expert Dave Ramsey, who calls it the&nbsp;&quot;Debt Snowball&quot; Plan. </p>
                <p>Sometimes it&rsquo;s easier to stay motivated if you pay off some small debts first so you feel empowered and inspired to keep paying off the rest of your credit card debt.&nbsp;</p>
                <p>
                    <strong>Advantages:</strong>
                    {' '}
                    You might feel more motivated to keep paying off debt if you can see those balances disappearing with each passing month.&nbsp;
                </p>
                <p>
                    <strong>Disadvantages:</strong>
                    {' '}
                    You will pay more money in credit card interest than you would have by focusing on the higher interest cards first.
                </p>
                <h2 id="heres-what-to-do-if-you-cant-pay">Here&rsquo;s what to do if you can&rsquo;t pay</h2>
                <p>Things happen in life: sometimes you lose your job, other times you just don&rsquo;t have enough money that month to cover your credit card bill. </p>
                <p>Here&rsquo;s what you can do in these situations.</p>
                <h3>1. Determine how much you can pay, then call the credit card company</h3>
                <p>Take a careful look at your finances and determine how much you can afford to pay the credit card company. </p>
                <p>Many companies will be more likely to negotiate if you offer to pay at least a portion of the amount due. </p>
                <p>
                    Bankruptcy attorney
                    {' '}
                    <a target="_blank" href="http://www.hargravelaw.com/" rel="noreferrer">John Hargrave</a>
                    {' '}
                    recommends not using your IRA or 401 (k) to pay your credit card debt. &quot;Your IRA and 401(k) funds are exempt from creditors – you will be able to protect all of your IRA and 401(k),&quot; Hargrave says.
                </p>
                <p>
                    &quot;Paying credit cards with your retirement
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=credit-card-debt&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    is throwing money away.&quot;
                </p>
                <p>He also recommends not paying your credit card bill with a home equity loan or line of credit because you are turning an unsecured debt into a secured debt that could put your home at risk for foreclosure.</p>
                <p>Then, as hard as it may be, pick up the phone and call your credit card company. </p>
                <p>
                    Cash flow expert
                    {' '}
                    <a target="_blank" href="http://www.moneyripples.com/" rel="noreferrer">Chris Miles</a>
                    {' '}
&nbsp;says most people avoid making the call when things get rough and he encourages them to call their creditors, explain their situation and ask about the available options.
                </p>
                <p>&quot;Many creditors have become more willing to help you out by offering a reprieve for one, two, or more months, depending on financial circumstances, without hurting your credit,&quot; Miles says. </p>
                <p>He also strongly recommends not avoiding their calls for updates on the dates of payments, which will make them less willing to work with you in the future.</p>
                <h3>2. Pay as much of the owed amount as you can</h3>
                <p>If you credit card company modified either your amount due or your due date, be sure to stick to your end of the bargain. </p>
                <p>If your company was not willing to work with you, pay as much towards the amount due as soon as you can. </p>
                <p>However, you should make sure that your utilities and mortgage are paid before the credit card bill since your home can go into foreclosure and your services can be shut off.</p>
                <p> </p>
                <p><LazyLoadImage src="/media/u-s-mortgage-debt-outstanding-3.png" alt="U.S. Mortgage Debt Outstanding" /></p>
                <h3>3. Make a plan for next month</h3>
                <p>Make sure that next month you don&rsquo;t find yourself in the same situation. </p>
                <p>Miles recommends finding ways to reduce expenses and increasing your income. </p>
                <p>&quot;Look for ways to save on utilities, food, and other costs. You could even sell off clutter in your house, garage, or storage,&quot; Miles says. </p>
                <p>&quot;At the same time, see if you can earn a raise or promotion by speaking with your boss, or if an entrepreneur, look for ways to add more products and services.&quot;</p>
                <p>Of course, these options don&rsquo;t always work, especially if you&rsquo;ve lost your job and you have no source of income at all.</p>
                <p>Here are a few borrowing options you may consider to help you make ends meet. </p>
                <h3>Borrowing options if unemployed</h3>
                <p>The first types of loans are those that allow you to borrow against something that you already have invested in. </p>
                <p>
                    Among them are a&nbsp;home equity loan (or line of credit), borrowing against a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=credit-card-debt&amp;sub2=life-insurance" rel="noopener noreferrer" target="_blank">life insurance</a>
                    {' '}
                    policy or a 401K retirement account.
                </p>
                <p>If you don&rsquo;t have much accumulated debt, you&rsquo;ll have a better chance to qualify for the equity line. </p>
                <p>The other loans are not based on income, but what you have already accumulated in value. Borrowing against your own retirement will hamper its growth — even if you repay it with interest — but hard times make for hard decisions.</p>
                <p>You could also consider asking someone with solid credit and a secure source of income to co-sign a loan, sharing the risk with you. </p>
                <p>
                    Your job loss might also give you an opportunity to qualify for
                    {' '}
                    <a target="_blank" href="https://studentscholarships.org/foundation/24400/new_educational_opportunities_for_unemployed_workers.php#sthash.zCickEby.dpbs" rel="noreferrer">government grant or loan programs</a>
&nbsp;to help with educational opportunities to better position you in the workforce.
                </p>
                <h3>Loan options for emergencies</h3>
                <p>Sometimes you may just need money for a short period of time, in which case you might want to consider these options.</p>
                <p>
                    <strong>Auto title loans.</strong>
                    {' '}
                    You use the value of your car (if you don&rsquo;t owe any money on it) as security for a loan with a typically steep interest rate.

                </p>
                <p>
                    The
                    {' '}
                    <a target="_blank" href="http://www.responsiblelending.org/research-publication/driven-disaster" rel="noreferrer">Center for Responsible Lending</a>
                    {' '}
&nbsp;says a typical title loan of $951 will cost a borrower $2,140 in interest.
                </p>
                <p>
                    <strong>Pawnbroker loans.</strong>
                    {' '}
                    Pawnbrokers will let you bring in items of value, anything from a musical instrument to jewelry, and let you borrow against a percentage of what they say it&rsquo;s worth.
                </p>
                <p>After receiving the cash you typically would have a month to repay the loan, or pay for an extension, or risk forfeiting the item in lieu of paying.</p>
                <p>
                    <strong>Same-day loans.</strong>
                    {' '}
                    While same-day loans allow you to borrow in advance of receiving a check from your employer, you may still qualify even if you are unemployed.
                </p>
                <p>If you have an unemployment check, some lenders will let you borrow against it. </p>
                <p>However, these loans come with high fees that make them costly and inappropriate for expenses other than emergency needs.</p>
                <h2 id="how-to-avoid-damaging-your-creditand-repair-it-if-it-is">How to avoid damaging your credit—and repair it if it is</h2>
                <p>Your credit score can hang in the balance while you fight to pay off credit cards. </p>
                <p>The first step is to avoid making mistakes that might damage your credit.</p>
                <p>The following are all common mistakes that people make, often without realizing the effects.</p>
                <h3>1. Making late payments</h3>
                <p>
                    FICO, the company that compiles and reports credit scores, says that
                    {' '}
                    <a target="_blank" href="http://www.myfico.com/crediteducation/whatsinyourscore.aspx" rel="noreferrer">payment history represents 35% of your credit score</a>
                    {' '}
                    — meaning that even a single reported delinquent payment can drop a score by up to 100 points.
                </p>
                <p>That results in more expensive credit in the future, and higher interest rates on some accounts you have right now.</p>
                <h3>2. Not monitoring credit reports</h3>
                <p>
                    Given the effects even a single damaging entry can have on your credit report, it&rsquo;s surprising that the
                    {' '}
                    <a target="_blank" href="http://www.ftc.gov/os/2013/02/130211factareport.pdf" rel="noreferrer">Consumer Financial Protection Bureau</a>
                    {' '}
                    found less than 20% of consumers check that report annually — and that over a quarter of credit reports have substantive errors.
                </p>
                <p>The difference between good and fair credit can mean 3% extra interest on a car loan — adding up to spending more than $1,000 over the life of the account.</p>
                <h3>3. Making only the minimum payments</h3>
                <p>Minimum payments on debt accounts are calculated to extend the life of the loan as much as possible, and to maximize lender profits. </p>
                <p>
                    Putting $1,000 on a credit card with an
                    {' '}
                    <a target="_blank" href="http://www.creditcards.com/credit-card-news/interest-rate-report-010213-unchanged-2121.php" rel="noreferrer">average APR of 14.6 percent</a>
&nbsp;costs $12 per month in interest. At an average minimum payment
                    {' '}
                    <a target="_blank" href="http://www.bankrate.com/calculators/managing-debt/minimum-payment-calculator.aspx" rel="noreferrer">(based on industry standard of interest+1% of balance)</a>
                    {' '}
                    of $25, that loan will take 4 1/2 years to pay off, at a total interest of $375 — more than one-third the cost of the purchase.
                </p>
                <p>Paying $50 a month on the same account of debt will shrink the time to pay it off to less than two years, with an interest cost of just $139.</p>
                <h3>4. Borrowing to buy liabilities</h3>
                <p>Taking on debt for a home or for education has traditionally been a good investment, and largely remains so even in this difficult economy. </p>
                <p><LazyLoadImage src="/media/type-of-debt-owned-by-americans-4.png" alt="Type of Debt Owned by Americans" /></p>
                <p>
                    However,
                    {' '}
                    <a target="_blank" href="http://www.newyorkfed.org/newsevents/news/research/2012/an121127.html" rel="noreferrer">15% of American debt is for consumer spending</a>
                    , and buying cars is one of the top three uses Americans report for accessing equity in their homes.
                </p>
                <p>This violates one of the prime rules of successful finances:&nbsp;Borrow for things that increase in value and pay cash for things that lose value.</p>
                <h3>5. Restructuring without changing spending habits</h3>
                <p>Debt consolidation works by combining existing debts into a single large account, with a longer term of payment and a single bill each month. </p>
                <p>The result is an easier-to-manage debt situation that can potentially save thousands. </p>
                <p>
                    The
                    {' '}
                    <a target="_blank" href="http://www.consumer.ftc.gov/articles/0150-coping-debt" rel="noreferrer">Federal Trade Commission</a>
                    {' '}
                    warns that this solution only works if you combine it with a change to the habits that got you in debt.
                </p>
                <p>Without that shift, families end up with high debt and multiple monthly payments&nbsp;in addition to&nbsp;the bill from their earlier consolidation.</p>
                <h3>6. Accepting higher spending limits</h3>
                <p>Even credit cards — the riskiest form of consumer debt — can work well if you pay off the balance every month, preferably during the grace period offered by most high-quality cards. &nbsp;</p>
                <p>Those higher limits can lead to maintaining a balance month after month, and paying interest fees you had avoided earlier. </p>
                <p>
                    Other versions of this same problem include
                    {' '}
                    <a target="_blank" href="https://www.newyorkfed.org/medialibrary/interactives/householdcredit/data/pdf/HHDC_2017Q1.pdf" rel="noreferrer">using home equity to finance consumer purchases</a>
                    , and using
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=credit-card-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    on
                    {' '}
                    <a target="_blank" href="http://www.cbsnews.com/8301-505146_162-42140926/abusing-student-loans-and-11-other-college-money-mistakes/" rel="noreferrer">expenses other than education</a>
                    .
                </p>
                <p>If you have already made some of these mistakes and your credit score is suffering as a result, you can still bring it back up to speed with these five simple steps.</p>
                <h3>1. Set up payment alerts</h3>
                <p>You can use an online banking system or request email alerts from your creditors to remind you to pay a bill. </p>
                <p>Another option is to set up automatic payments through your creditors, but this typically only makes the minimum payment and won&rsquo;t help reduce your balance.</p>
                <h3>2. Get current on your bills</h3>
                <p>Take any available cash and bring all your accounts up-to-date to improve your score and eliminate late payment fees and over-limit fees.</p>
                <h3>3. Pay down your debt</h3>
                <p>Andrew Sprauve, director of public relations for FICO, says that you should aim to keep your use of credit below 20 percent of your credit limit. </p>
                <p>Stop using your credit cards and tackle one card at a time to pay down your balance. </p>
                <p>Start with the smallest balance for a psychological boost or pay down the debt with the highest interest rate.</p>
                <h3>4. Don&rsquo;t close any accounts</h3>
                <p>You may want to eliminate your credit cards, but this could actually hurt your score because you&rsquo;ll have a lower amount of available credit and your credit utilization will be higher.</p>
                <h3>5. Don&rsquo;t open new accounts</h3>
                <p>Applying for new credit can cause a negative impact, but in the long-term, having a lower percentage of debt compared to available credit can be a plus.</p>
                <h2 id="life-after-credit-card-debt">Life after credit card debt</h2>
                <p>If your credit has been damaged, it can take months or even years to fully repair it.</p>
                <p>
                    For example,
                    {' '}
                    <a href="/bankruptcy/">declaring bankruptcy can stay on your report for up to ten years at a time</a>
                    .
                </p>
                <p>If you catch the problem before it becomes too severe, though, you can not only pay off your credits cards—you can do it in a reasonable amount of time, too. </p>
                <p>What steps have you taken to pay off credit card debt?</p>
                <p>Has any of the advice above worked for you?</p>
                <p>Let us know in the comments below!</p>
            </>
        ),
    };
    return (
        <Post article={article} />
    );
};

export default CreditCardDebtPage;
